import React, { useContext, useEffect, useState, useRef } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { RemoveScroll } from "react-remove-scroll";
import { useClickAway } from "react-use";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageBackgroundColor } from "../components/context/page-background-color";

// Components
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";
import { PageSEO } from "../components/seo/page-seo";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Utils
import { ImageOrientation } from "../components/utils/image-orientation";

const Page = styled.div`
  margin: 0 0 170px 0;

  @media (max-width: 768px) {
    margin: 0 0 55px 0;
  }

  & p {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.05em;
  }

  & article {
    &:nth-of-type(1n + 1) {
      & .content {
        background-color: #a347a5;
      }
    }
    &:nth-of-type(2n + 1) {
      & .content {
        background-color: #dfee86;
      }
    }
    &:nth-of-type(3n + 1) {
      & .content {
        background-color: #ff8513;
      }
    }
    &:nth-of-type(4n + 1) {
      & .content {
        background-color: #4383ef;
      }
    }

    & .content {
      padding: 20px;

      & img {
        width: 100%;
        height: 100%;

        &.portrait {
          object-fit: contain;
        }

        &.landscape {
          object-fit: cover;
        }
      }
    }

    & .article-text {
      margin: 20px 0 0 0;

      & h1,
      & p {
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.06em;

        margin: 0;

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.05em;
        }
      }

      @media (max-width: 768px) {
        margin: 5px 0 0 0;
      }
    }

    @media (max-width: 768px) {
      margin: 0 0 50px 0;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;

  @media (max-width: 1350px) {
    grid-column-gap: 20px;
  }

  & .content-grid {
    grid-column: 1 / 12;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 160px;
    grid-row-gap: 60px;

    @media (max-width: 1350px) {
      grid-column-gap: 80px;
    }

    @media (max-width: 1100px) {
      grid-column-gap: 40px;
    }
  }

  @media (max-width: 768px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;

    & .content-grid {
      grid-column: unset;

      display: unset;
      grid-template-columns: unset;
      grid-column-gap: unset;
      grid-row-gap: unset;
    }
  }
`;

const DesktopFilters = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;

  @media (max-width: 1350px) {
    grid-column-gap: 20px;
  }

  @media (max-width: 1100px) {
    display: flex;
    grid-template-columns: unset;
    grid-column-gap: unset;
  }

  grid-column: 1 / 13;

  margin: 25px 0 20px 0;

  & button {
    margin: 0 15px 0 0;

    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;

    text-transform: capitalize;
    text-align: left;

    &:last-of-type {
      margin: 0;
    }

    & span {
      opacity: 0;
      transition: 250ms opacity ease;
    }

    &:hover,
    &.active-tag {
      & span {
        opacity: 1;
      }
    }

    & svg {
      margin: 0 7px 0 0;
    }

    &.view-all {
      @media (max-width: 1100px) {
        margin: 0 15px 0 0;
      }
    }
  }

  & .tags-container {
    grid-column: 2 / 12;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const MobileFilters = styled.div`
  margin: 0 0 20px 0;
  grid-column: 1 / 13;

  & .mobile-filters-container {
    & button {
      display: block;

      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.05em;

      margin: 0 0 10px 0;
      text-transform: capitalize;
      text-align: left;

      color: #163262;

      & span {
        opacity: 0;
        transition: 250ms opacity ease;
      }

      &:hover,
      &.active-tag {
        & span {
          opacity: 1;
        }
      }

      & svg {
        margin: 0 7px 0 0;
      }
    }

    & .tags-container {
    }
  }

  &.visible {
    & .mobile-filters-container {
      position: absolute;

      top: 0;
      left: 0;
      right: 0;

      background-color: #5694fd;

      z-index: 10;

      padding: 124px 40px 20px 40px;

      @media (max-width: 768px) {
        padding: 107px 20px 20px 20px;
      }

      & button {
        color: #163262;

        &.toggle {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.06em;
        }
      }
    }
  }

  & .toggle {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.06em;
  }

  @media (max-width: 768px) {
    & .mobile-filters-container {
      & button {
        & span {
          display: none;
        }

        &.active-tag {
          & span {
            display: inline-block;
          }
        }
      }
    }
  }
`;

const News = ({ data, location }) => {
  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );

  // Tags
  const [activeTag, setActiveTag] = useState(`all`);

  // Mobile Filters
  const [isMobileFiltersVisible, setMobileFiltersVisible] = useState(false);
  const mobileFiltersRef = useRef(null);

  useClickAway(mobileFiltersRef, () => {
    setMobileFiltersVisible(false);
  });

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 1075px)");

  useEffect(() => {
    setPageBackgroundColor({
      text: `#4383EF`,
      background: `#ffffff`,
    });
  }, []);

  const scrollToElement = element => {
    var rect = document.getElementById(element).getBoundingClientRect();
    var offsetTop = rect.top - 100;
    window.scrollTo(0, offsetTop);
  };

  useEffect(() => {
    setMobileFiltersVisible(false);
  }, [activeTag]);

  useEffect(() => {
    if (isMobileFiltersVisible === true) {
      document.body.classList.add(`mobile-filters-open`);
    } else {
      document.body.classList.remove(`mobile-filters-open`);
    }
  }, [isMobileFiltersVisible]);

  useEffect(() => {
    if (
      location !== null &&
      location !== undefined &&
      location !== `undefined`
    ) {
      if (
        location.state !== null &&
        location.state !== undefined &&
        location.state !== `undefined`
      ) {
        if (location.state.activeTag !== undefined) {
          setActiveTag(location.state.activeTag);

          if (location.state.articleId !== undefined) {
            const scrollWithTimeout = setTimeout(() => {
              scrollToElement(location.state.articleId);
            }, 50);
            return () => clearTimeout(scrollWithTimeout);
          }
        }
      }
    }
  }, [location]);

  const tags = Array.from(
    new Set(
      data.allPrismicNewsArticle.edges
        .map(article => article.node.data.published_date)
        .flat()
    )
  )
    .sort((a, b) => b - a)
    .map((tag, index) => (
      <button
        key={`single_news_tag_${index}`}
        onClick={() => setActiveTag(tag)}
        className={activeTag === tag ? `active-tag` : ``}
      >
        <span>(</span>
        {tag}
        <span>)</span>
      </button>
    ));

  const content = data.allPrismicNewsArticle.edges
    .filter(content => {
      if (activeTag === `all`) {
        return content;
      } else {
        if (activeTag === content.node.data.published_date) {
          return content;
        }
      }
    })
    .map((content, index) => (
      <article
        id={content.node.id}
        key={`single_news_article_${index}`}
        // className={colorsArray[Math.floor(Math.random() * colorsArray.length)]}
      >
        <Link to={content.node.url} state={{ activeTag: activeTag }}>
          <AspectRatioImageContainer image={null} padding={`66.667`}>
            {content.node.data.image.fluid !== null && (
              <img
                className={ImageOrientation(content.node.data.image)}
                srcSet={content.node.data.image.fluid.srcSetWebp}
                src={content.node.data.image.fluid.srcWebp}
                alt={content.node.data.image.alt}
                loading={index <= 3 ? `eager` : `lazy`}
              />
            )}
          </AspectRatioImageContainer>
        </Link>

        <div className="article-text">
          <div
            dangerouslySetInnerHTML={{
              __html: content.node.data.title.html,
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: content.node.data.subtitle.html,
            }}
          />
          <p>{content.node.data.dates}</p>
        </div>
      </article>
    ));

  return (
    <>
      <PageSEO
        title={data.prismicNews.data.title.text}
        description={null}
        image={null}
        url={`https://yinkashonibare.com${data.prismicNews.url}`}
      />
      <Page>
        {isDesktop ? (
          <DesktopFilters>
            <button
              onClick={() => setActiveTag(`all`)}
              className={
                activeTag === `all` ? `active-tag view-all` : ` view-all`
              }
            >
              <span>(</span>View All<span>)</span>
            </button>

            <div className="tags-container">
              <div className="tags">{tags}</div>
            </div>
          </DesktopFilters>
        ) : (
          <MobileFilters
            className={isMobileFiltersVisible ? `visible` : `hidden`}
            ref={mobileFiltersRef}
          >
            <button
              className="toggle open"
              onClick={() => {
                setMobileFiltersVisible(!isMobileFiltersVisible);
              }}
            >
              Filter {isMobileFiltersVisible ? `-` : `+`}
            </button>

            {isMobileFiltersVisible && (
              <RemoveScroll>
                <div className="mobile-filters-container">
                  <button
                    className="toggle close"
                    onClick={() => {
                      setMobileFiltersVisible(false);
                    }}
                  >
                    Filter -
                  </button>

                  <button
                    onClick={() => setActiveTag(`all`)}
                    className={
                      activeTag === `all` ? `active-tag view-all` : ` view-all`
                    }
                  >
                    <span>(</span>View All<span>)</span>
                  </button>

                  <div className="tags-container">
                    <div className="tags">{tags}</div>
                  </div>
                </div>
              </RemoveScroll>
            )}
          </MobileFilters>
        )}

        <Grid>
          <div className="content-grid">{content}</div>
        </Grid>
      </Page>
    </>
  );
};

export default withPreview(News);

export const query = graphql`
  {
    prismicNews {
      url
      data {
        title {
          text
        }
      }
    }
    allPrismicNewsArticle(
      sort: { fields: data___published_date, order: DESC }
    ) {
      edges {
        node {
          url
          id
          data {
            title {
              html
            }
            subtitle {
              html
            }
            dates
            published_date(formatString: "YYYY")
            image {
              fluid {
                srcWebp
                srcSetWebp
              }
              alt
              dimensions {
                height
                width
              }
            }
          }
        }
      }
    }
  }
`;
